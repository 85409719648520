import React from 'react';
import { CurveMask } from '../CurveMask';
import { HeroWrapperContainer } from './CorpVideoHeroWrapperContainer';
import { Background, HeroContainer, MaskBottom } from './styles';
import { ICorpVideoHeroProps } from './types';
import { useRouter } from 'next/router';
import { BannerContent } from './components/BannerContent';
import clsx from 'clsx';

export const CorpVideoHero: React.FC<ICorpVideoHeroProps> = ({
  bannerImage: image,
  title,
  description,
  superTitle,
  videoId,
  imageAlt,
  copy,
  mobileImage,
  textColor,
  bannerSize,
  contentAlignment,
  verticalAlignment,
  linkComponent,
  darkOverlay,
}: ICorpVideoHeroProps) => {
  const { locale: market } = useRouter();
  return (
    <HeroWrapperContainer data-test="hero-wrapper-container">
      <Background className="curve-mask-top" $customColor={'rgb(1, 14, 84)'}>
        <MaskBottom className="curve-mask-bottom">
          <HeroContainer
            data-test="hero-container"
            className={clsx('relative overflow-hidden', {
              'h-[577px] md:h-[400px]': bannerSize === 'short',
              'h-[580px]': bannerSize === 'tall',
            })}
          >
            <BannerContent
              bannerImage={image}
              mobileImage={mobileImage}
              title={title}
              superTitle={superTitle}
              description={description}
              videoId={videoId}
              imageAlt={imageAlt}
              textColor={textColor}
              darkOverlay={darkOverlay}
              contentAlignment={contentAlignment}
              verticalAlignment={verticalAlignment}
              linkComponent={linkComponent}
              copy={copy}
            />
          </HeroContainer>
          <CurveMask />
        </MaskBottom>
      </Background>
    </HeroWrapperContainer>
  );
};
